<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>team-meeting-message-question_1</title>
    <path
      d="M19.5,11.5a1.753,1.753,0,0,0,1.75-1.75v-8A1.753,1.753,0,0,0,19.5,0H5.5a1.753,1.753,0,0,0-1.75,1.75v8A1.753,1.753,0,0,0,5.5,11.5h6a.249.249,0,0,1,.25.25v2a.749.749,0,0,0,.463.693.738.738,0,0,0,.287.057.75.75,0,0,0,.53-.219l2.707-2.71a.251.251,0,0,1,.177-.073Zm-4.53-1.281L13.677,11.51a.25.25,0,0,1-.427-.176v-.587A.75.75,0,0,0,12.5,10h-7a.251.251,0,0,1-.25-.25v-8A.251.251,0,0,1,5.5,1.5h14a.251.251,0,0,1,.25.25v8a.251.251,0,0,1-.25.25h-4A.75.75,0,0,0,14.97,10.216Z"
    />
    <circle cx="4.88" cy="16.249" r="3.25" />
    <path d="M4.88,19.5a4.969,4.969,0,0,0-4.867,3.89A.5.5,0,0,0,.5,24H9.26a.5.5,0,0,0,.487-.61A4.969,4.969,0,0,0,4.88,19.5Z" />
    <circle cx="19.12" cy="16.249" r="3.25" />
    <path d="M23.987,23.389a4.99,4.99,0,0,0-9.734,0,.5.5,0,0,0,.487.61H23.5a.5.5,0,0,0,.487-.61Z" />
    <path d="M12.5,2.5a2.253,2.253,0,0,0-2.25,2.25.75.75,0,0,0,1.5,0,.75.75,0,1,1,.75.75.75.75,0,0,0,0,1.5,2.25,2.25,0,1,0,0-4.5Z" />
    <circle cx="12.5" cy="8.499" r="0.75" />
  </svg>
</template>
